export { matchers } from './matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')];

export const server_loads = [];

export const dictionary = {
	"/": [5],
	"/about": [6],
	"/app/(navigable)": [7,[2,3]],
	"/app/calendar": [8,[2]],
	"/app/call": [9,[2,4]],
	"/app/settings/[...section]": [10,[2]],
	"/forgot-password": [11],
	"/login": [12],
	"/signout": [13],
	"/signup": [14]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};